
import { Component, Mixins } from 'vue-property-decorator'
import { FormPage } from '@/mixins'
import { Services } from 'tradingmate-modules/src/services'
import ImageGallery from '@/components/inputs/ImageGallery.vue'
import Copyable from '@/components/inputs/Copyable.vue'
import Modal from '@/components/actions/Modal.vue'
import { InputDateTime, InputText, InputTextarea } from '@/components/inputs'
// import { OfferReadDto, OfferStatus, OfferUpdateDto } from 'tradingmate-modules/src/models/api/offers'
import ContentHtmlInput from '@/components/inputs/ContentHtmlInput.vue'
import { BlogModel, BlogPostStatus } from '../../../../../tradingmate-modules/src/models/api/blogs'
import UpdateBlogModel from '../../../../../tradingmate-modules/src/models/api/blogs/UpdateBlogModel'

@Component({
  components: {
    ContentHtmlInput,
    ImageGallery,
    Copyable,
    Modal,
    InputText,
    InputDateTime,
    InputTextarea
  }
})
export default class OfferEdit extends Mixins(FormPage) {
  private offer: BlogModel | null = null
  private offerUpdateDto: UpdateBlogModel | null = null
  private savedSuccessfully = false;
  private saveUnseccessful = false

  private deleteModalActive = false;
  private pulishModalActive = false;
  private unpulishModalActive = false;

  mounted (): void {
    this.getBlog()
  }

  getBlog (): void {
    const slug = this.$route.params.slug
    if (!slug) {
      this.$router.push('/offers')
      return
    }

    Services.API.Blogs.GetBlogForPrivate(slug)
      .then((blog) => {
        this.offer = blog
        this.blogModelToUpdateBlogModel()
      }).catch(() => {
      // this.$router.push('/blogs')
      })
  }

  blogModelToUpdateBlogModel (): void {
    if (this.offer === null) return
    this.offerUpdateDto = {
      Heading: this.offer.Heading,
      Subheading: this.offer.Subheading,
      Status: this.offer.Status,
      ExternalLink1: this.offer.ExternalLinks[0],
      ExternalLink2: this.offer.ExternalLinks[1],
      Content: this.offer.Content,
      ExpirationDate: this.offer.ExpirationDate
    }
  }

  savePost (): void {
    if (this.offer === null) return
    if (this.offerUpdateDto === null) return

    this.httpError = null
    this.savedSuccessfully = false
    this.loading = true

    const scrollPoint = this.$refs.scrollPoint as HTMLElement
    scrollPoint.scrollIntoView({ behavior: 'smooth', block: 'end' })

    Services.API.Blogs.UpdateBlog(this.offer.BlogId, this.offerUpdateDto)
      .then((returnBlog) => {
        this.offer = returnBlog
        this.blogModelToUpdateBlogModel()
        this.savedSuccessfully = true
      }).catch((error) => {
        this.saveUnseccessful = true
        this.httpError = error
      }).finally(() => {
        this.loading = false
        this.scrollToTop()
      })
  }

  publishPost (): void {
    this.pulishModalActive = false
    if (this.offerUpdateDto === null) return
    this.offerUpdateDto.Status = BlogPostStatus.Published
    this.savePost()
  }

  unpublishPost (): void {
    this.unpulishModalActive = false
    if (this.offerUpdateDto === null) return
    this.offerUpdateDto.Status = BlogPostStatus.Draft
    this.savePost()
  }

  deletePost (): void {
    if (this.offer === null) return

    Services.API.Blogs.DeleteBlog(this.offer.BlogId)
      .catch((error) => {
        this.httpError = error
      })
    this.$router.push({ path: '/offers' })
  }

  scrollToTop (): void {
    const scrollPoint = this.$refs.scrollPoint as HTMLElement
    scrollPoint.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }
}
