
import { FileAttachmentType, GetFileModel } from 'tradingmate-modules/src/models/api/files'
import AttachedObjectType from 'tradingmate-modules/src/models/api/files/AttachedObjectType'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import FormField from '@/components/forms/FormField.vue'
import InputText from '@/components/inputs/InputText.vue'
import { FormPage } from '@/mixins'
import FileUpdateDto from 'tradingmate-modules/src/models/api/files/FileUpdateDto'

@Component({
  components: { InputText, FormField }
})
export default class MyWebsiteImage extends Mixins(FormPage) {
  @Prop({ required: true })
  private readonly attachedObjectId!: string;

  @Prop({ required: true })
  private readonly fileAttachmentType!: FileAttachmentType;

  @Prop({ default: AttachedObjectType.MyWebsite })
  private readonly attachedObjectType!: AttachedObjectType;

  @Prop({ default: false })
  private readonly showFields!: boolean;

  @Prop({ default: '' })
  private readonly altText!: string;

  @Prop({ default: 1 })
  private readonly limit!: number;

  private gettingObject = false

  private updatingFile = false

  private attaching = false

  private detaching = false

  private updateDebounce = 0

  files: GetFileModel[] = [];

  uploading = false;

  private errored = false;

  private errorMessage = '';

  imageFileTypes: string[] = [
    'image/avif',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/webp'
  ]

  get atLimit (): boolean {
    return this.limit ? this.files.length >= this.limit : false
  }

  async mounted (): Promise<void> {
    await this.getFiles()
  }

  async getFiles (): Promise<void> {
    this.gettingObject = true
    try {
      this.files = await Services.API.Files.GetForObject(
        this.attachedObjectId,
        this.attachedObjectType,
        this.fileAttachmentType
      )
    } catch (errors) {
      console.log(errors)
    } finally {
      this.gettingObject = false
    }
  }

  async updateFile (file: GetFileModel): Promise<void> {
    window.clearTimeout(this.updateDebounce)
    this.updatingFile = true
    const fileUpdateDto: FileUpdateDto = {
      Description: file.Description,
      ButtonText: file.ButtonText,
      ExternalLink: file.ExternalLink
    }
    try {
      this.updateDebounce = window.setTimeout(async () => {
        console.log(file.FileId)
        await Services.API.Files.Update(file.FileId, fileUpdateDto)
        this.$emit('updated')
      }, 1000)
    } catch (error) {
      console.log(error)
    } finally {
      this.updatingFile = false
    }
  }

  async upload (): Promise<void> {
    const fi = document.createElement('input') as HTMLInputElement

    fi.type = 'file'

    fi.onchange = async () => {
      if (fi.files === null) return
      const file = fi.files[0]

      if (!this.imageFileTypes.includes(file.type)) {
        this.errored = true
        this.errorMessage = 'Unsupported file type, try one of the following: jpeg, png, gif, webp, avif'
        return
      }

      this.uploading = true

      if (file) {
        const uploadedFile = await this.uploadFile(file)
        if (uploadedFile === null) return
        await this.attach(uploadedFile.FileId)
        this.$emit('updated')
        this.files.push(uploadedFile)
      }
    }

    fi.click()
  }

  async uploadFile (file: File): Promise<GetFileModel | null> {
    this.uploading = true
    try {
      return await Services.API.Files.Upload(file, true)
    } catch (errors) {
      console.log(errors)
      return null
    } finally {
      this.uploading = false
    }
  }

  attach (fileId: string): void {
    this.attaching = true
    try {
      Services.API.Files.Attach(
        fileId,
        this.attachedObjectId,
        this.attachedObjectType,
        this.fileAttachmentType,
        this.files.length
      )
    } catch (errors) {
      console.log(errors)
    } finally {
      this.attaching = false
    }
  }

  detach (fileModel: GetFileModel): void {
    this.detaching = true
    try {
      const success = Services.API.Files.Detach(
        fileModel.FileId,
        this.attachedObjectId,
        this.attachedObjectType,
        this.fileAttachmentType
      )
      if (!success) return
      const index = this.files.findIndex(file => file.FileId === fileModel.FileId)
      this.files.splice(index, 1)
      this.$emit('updated')
    } catch (error) {
      console.log(error)
    } finally {
      this.detaching = false
    }
  }
}
