
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Modal extends Vue {
  @Prop({ default: false })
  public isActive!: boolean;

  @Prop({ default: '' })
  private readonly title!: string;

  @Prop({ default: 'primary' })
  private readonly scheme!: string;

  clickedOutside (): void {
    this.$emit('clickedOutside')
  }
}
