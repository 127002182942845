
import { Vue, Component, PropSync } from 'vue-property-decorator'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import CKEditorVariablePlugin from '@/app_code/Plugins/CKEditorVariablePlugin'

@Component({
  components: {
    ckeditor: CKEditor.component
  }
})
export default class ContentHtmlInput extends Vue {
  @PropSync('valueSync', { default: null })
  public value!: string | null;

  mounted (): void {
    //
  }

  public editorDisabled = false
  public editor = ClassicEditor
  editorConfig = {
    plugins: [
      EssentialsPlugin,
      BoldPlugin,
      ItalicPlugin,
      LinkPlugin,
      ParagraphPlugin,
      CKEditorVariablePlugin
    ],
    image: {
      toolbar: [
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side',
        'imageStyle:wrapText',
        'imageStyle:breakText',
        '|',
        'toggleImageCaption',
        'imageTextAlternative'
      ]
    },
    toolbar: {
      items: [
        'bold',
        'italic',
        'link',
        'undo',
        'redo',
        '|'
      ]
    }
  }
}
