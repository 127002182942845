enum AttachedObjectType {
    BusinessBranch,
    Blog,
    Event,
    MyWebsite,
    EmailTemplate,
    TradingMate
}

export default AttachedObjectType
